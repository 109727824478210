<template>
  <v-row class="app-invoice-preview">
    <v-col cols="12" md="9">
      <v-card v-if="billingData" id="invoice">
        <!-- Header -->
        <v-card-text class="py-9 px-8">
          <div class="invoice-header d-flex flex-wrap justify-space-between flex-column flex-sm-row">
            <!-- Left Content -->
            <div class="mb-8 mb-sm-0">
              <div class="d-flex align-center mb-6">
                <img :src="appLogo" alt="logo" contain class="me-3">
              </div>
            </div>
            <!-- Right Content -->
            <div>
              <span class="d-block">SIA LeadProm Media</span>
              <span class="d-block">Matisa street, 61 - 31, Riga, Latvia, LV-1009</span>
              <span class="d-block">Reg. number 40203230340</span>
              <span class="d-block">VAT LV40203230340</span>
              <span class="d-block">Email: billing@leadprom.com</span>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <!-- Payment Details -->
        <v-card-text class="py-9 px-8">
          <div class="payment-details d-flex justify-space-between flex-wrap flex-column flex-sm-row">
            <div>
              <p class="invoice-number font-weight-medium text-xl text--primary mb-4">
                Invoice #{{ billingData.company ? (billingData.company.id + '/') : '' }}{{ billingData.id.split('-')[0] }}
              </p>
              <p class="invoice-creation-date mb-2">
                <span>Date: </span>
                <span class="font-weight-semibold">{{ billingData.createdAt | moment('YYYY-MM-DD') }}</span>
              </p>
              <p class="invoice-due-date mb-2">
                <span>Due date: </span>
                <span class="font-weight-semibold">{{ billingData.createdAt | moment('add', '7 days', 'YYYY-MM-DD') }}</span>
              </p>
            </div>
          </div>
        </v-card-text>

        <!-- Table -->
        <v-simple-table class="purchased-items-table">
          <template #default>
            <tbody>
              <tr v-for="merchantBilling in billingData.merchantBillings" :key="merchantBilling.id" class='mastercard-alert'>
                <td>{{ merchantBilling.merchant.companyName }} Reseller Fee for {{ merchantBilling.period }}</td>
                <td>{{ merchantBilling.amount }} EUR</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <!-- Total -->
        <v-card-text class="py-9 px-8">
          <div class="invoice-total d-flex justify-space-between flex-column flex-sm-row">
            <v-spacer></v-spacer>
            <div>
              <table v-if="billingData.vat" class="vat-value w-full">
                <tr>
                  <td class="pe-16">VAT:</td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'text-right'">{{ billingData.vat }} %</th>
                </tr>
              </table>
              <v-divider class="mt-4 mb-3"></v-divider>
              <table class="w-full">
                <tr>
                  <td class="pe-16">Total:</td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'invoice-total-in-eur text-right'">{{ billingData.totalAmount }} EUR</th>
                </tr>
                <tr v-if="billingData.exchangeRate">
                  <td class="pe-16">Exchange rate:</td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'exchange-rate text-right'">
                    {{ billingData.exchangeRate }}
                  </th>
                </tr>
                <tr v-if="billingData.totalAmountConverted">
                  <td class="pe-16">Total in {{ billingData.currency }}:</td>
                  <th :class="$vuetify.rtl ? 'text-left' : 'invoice-total-in-usd text-right'">
                    {{ billingData.totalAmountConverted }} {{ billingData.currency }}
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" md="3">
      <v-card>
        <v-card-text>
          <v-btn class="billing-list-download-invoice-button mb-3" color="secondary" block outlined @click="downloadInvoice"> Download </v-btn>
          <v-btn class="billing-list-print-invoice-button mb-3" color="secondary" block outlined @click="printInvoice"> Print </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { ref } from '@vue/composition-api';
import store from '@/store';
import router from '@/router';
import { mdiSendOutline, mdiCurrencyUsd } from '@mdi/js';
import themeConfig from '@themeConfig';
import { getProfile } from '@/services/jwt.service';
import Roles from '@/enums/roles.enum';

export default {
  setup() {
    const isAdmin = getProfile().role === Roles.ADMIN;
    const billingData = ref(null);

    store
      .dispatch('resellerBilling/fetchById', { id: router.currentRoute.params.id })
      .then(response => {
        billingData.value = response.data;
      })
      .catch(error => {
        if (error.response.status === 404) {
          billingData.value = undefined;
        }
      });

    const printInvoice = () => {
      window.print();
    };

    const downloadInvoice = () => {
      store.dispatch('billing/downloadManagerInvoice',{ id: router.currentRoute.params.id }).then(response => {
        const fileURL = URL.createObjectURL(response);
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `Invoice #${billingData.value.id.split('-')[0] }.pdf`);
        document.body.appendChild(fileLink);
        fileLink.click();
        URL.revokeObjectURL(fileURL);
      });
    };

    return {
      isAdmin,
      billingData,
      printInvoice,
      downloadInvoice,
      appLogo: themeConfig.app.logo,
      icons: {
        mdiSendOutline,
        mdiCurrencyUsd,
      },
    };
  },
};
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

@media print {
  .v-application {
    background: none !important;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .product-buy-now,
  .app-invoice-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .app-invoice-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0 !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-invoice-preview {
    .invoice-header,
    .payment-details,
    .invoice-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}
</style>
